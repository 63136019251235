import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getColor, breakpoint } from '../../shared/theme'
import SectionTransition from '../../elements/activeSectionDefault'
import ArrowButton from '../../elements/arrowButton'

const ImageTextLinkWrapper = styled(SectionTransition)`
	display: flex;
	flex-wrap: wrap;

	@media ${breakpoint('tablet')} {
		min-height: 700px;
	}
`

const ImageWrapper = styled.div`
	width: 100%;
	overflow: hidden;
	display: block;
	position: relative;

	&::before
	{
		content: '';
		display: block;
		width: 100%;
		padding-top: ${ () => { return `${520 / 375 * 100}%`}}
	}

	@media ${breakpoint('tablet')} {
		width: 50%;

		&::before {
			display: none;
		}
	}
`

const Image = styled.img`
	object-fit: cover;
	height: 100%;
	width: 100%;
`

const ContentWrapper = styled.div`
	width: 100%;
	background-color: ${getColor('white')};
	color: ${getColor('black')};
	padding: 44px 20px 52px;

	@media ${breakpoint('tablet')} {
		width: 50%;
		padding: 80px 30px 40px 85px;
		background-color: ${getColor('black')};
		color: ${getColor('white')};
	}

	@media ${breakpoint('laptop')} {
		width: 50%;
		padding: 80px 175px 40px 85px;
	}
`

const Content = styled.div`
	margin-bottom: 40px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 80px;
	}
`

const ImageTextLink = ({ isActive, image, copy, link }) => {
	return (
		<ImageTextLinkWrapper isActive={isActive}>
			<ImageWrapper>
				{image && <Image src={image.mediaItemUrl} className="bg-cover" alt={image.altText} />}
			</ImageWrapper>
			<ContentWrapper className="content">
				<Content dangerouslySetInnerHTML={{ __html: copy }} />
				<ArrowButton title={link.title} link={link.url} colortheme="switch" />
			</ContentWrapper>
		</ImageTextLinkWrapper>
	)
}

ImageTextLink.propTypes = {
	isActive: PropTypes.bool,
}

ImageTextLink.defaultProps = {
	isActive: true,
}

export default ImageTextLink
