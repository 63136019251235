import React from 'react'

import SEO from '../../components/SEO'

import InviewWrapper from '../../elements/inviewWrapper'
import PageHeaderImage from '../../components/pageHeaderImage'
import PageIntroText from '../../components/pageHeaderIntro'
import CategoryTiles from '../../components/categoryTiles'
import StatsTicker from '../../components/statsTicker'
import TextLinkSection from '../../components/textLinkSection'
import ProcessSection from '../../components/processSection'
import ImageTextLink from '../../components/imageTextLink'

const AboutPage = ({ pageContext }) => {
	const {
		page: { title, aboutFields, default_page_header: pageHeader },
	} = pageContext

	const {
		capabilitiesCopy,
		capabilitiesGallery,
		capabilitiesHeading,
		capabilitiesLink,
		capabilitiesSubheading,
		processBottomCopy,
		processBottomImage,
		processBottomLink,
		processHeading,
		processCopy,
		processLink,
		processSubheading,
		statistics,
		statisticsGallery,
	} = aboutFields

	return (
		<>
			<SEO title={title} />
			<PageHeaderImage content={pageHeader} />
			<InviewWrapper>
				<PageIntroText content={pageHeader} />
				<CategoryTiles categories={statisticsGallery} />
				<StatsTicker statistics={statistics} />

				<TextLinkSection
					subheading={capabilitiesSubheading}
					heading={capabilitiesHeading}
					copy={capabilitiesCopy}
					link={capabilitiesLink}
					gallery={capabilitiesGallery}
				/>

				<ProcessSection
					subheading={processSubheading}
					heading={processHeading}
					copy={processCopy}
					link={processLink}
				/>
				<ImageTextLink
					image={processBottomImage}
					copy={processBottomCopy}
					link={processBottomLink}
				/>
			</InviewWrapper>
		</>
	)
}

export default AboutPage
