import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import H6 from '../../elements/typography/h6'
import InnerWrapper from '../../elements/innerWrapper'
import Grid from '../../elements/grid'
import ArrowButton from '../../elements/arrowButton'
import SectionTransition from '../../elements/activeSectionDefault'
import FullWidthSlider from '../../components/fullWidthSlider'
import { Content } from '../../elements/wyswyg'
import { breakpoint, getColor } from '../../shared/theme'

const Title = styled(H6)`
	grid-column: span 12;
	color: ${getColor('white')};
	text-transform: uppercase;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: 22px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 30px;
		height: 1px;
		background: ${getColor('white')};
	}

	@media ${breakpoint('tablet')} {
		grid-column: span 2;
		margin-bottom: 0;

		&::after {
			display: none;
		}
	}
`

const TextLinkWrapper = styled(SectionTransition)`
	padding: 52px 0;
	background-color: ${getColor('black')};

	@media ${breakpoint('tablet')} {
		padding: 88px 0 184px;
	}
`

const ContentWrapper = styled.section`
	color: ${getColor('white')};
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 8;
	}

	@media ${breakpoint('desktop')} {
		grid-column: span 6;
	}
`

const ContentInner = styled(Content)`
	margin-bottom: 25px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 62px;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;

	@media ${breakpoint('tablet')} {
		flex-direction: column;
	}
`

const TextLinkSection = ({ isActive, subheading, copy, link, gallery }) => {
	return (
		<Wrapper>
			<TextLinkWrapper isActive={isActive}>
				<InnerWrapper>
					<Grid>
						<Title>{subheading}</Title>
						<ContentWrapper>
							<ContentInner
								dangerouslySetInnerHTML={{ __html: copy }}
								colorTheme="dark"
							/>
							{link && <ArrowButton title={link.title} link={link.url} white />}
						</ContentWrapper>
					</Grid>
				</InnerWrapper>
			</TextLinkWrapper>
			<FullWidthSlider gallery={gallery} />
		</Wrapper>
	)
}

TextLinkSection.propTypes = {
	isActive: PropTypes.bool,
	subheading: PropTypes.string,
	copy: PropTypes.string,
	link: PropTypes.object,
}

TextLinkSection.defaultProps = {
	isActive: true,
	subheading: '',
	copy: '',
	link: {},
}

export default TextLinkSection
