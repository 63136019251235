import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { getColor, breakpoint } from '../../shared/theme'
import CountUp, { useCountUp } from 'react-countup'

const TickerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	background-color: ${getColor('greyLight')};
	padding: 20px;
	margin-bottom: 20px;
	grid-column: -1 / 1;
	align-items: flex-start;

	&:last-child {
		margin-bottom: 0;
	}

	@media ${breakpoint('tablet')} {
		align-items: center;
		background-color: white;
		padding: 0;
		grid-column: span 4;
		margin-bottom: 0;
	}
`

const TickerInner = styled.div`
	display: inline-block;
`

const StatisticNumber = styled.h1`
	font-size: 40px;
	font-weight: 800;
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 15px;
	display: block;

	&::before {
		content: attr(title);
		display: block;
		height: 0;
		overflow: hidden;
		visibility: hidden;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 45px;
		height: 2px;
		background-color: ${getColor('black')};

		@media ${breakpoint('tablet')} {
			height: 4px;
		}
	}

	@media ${breakpoint('laptop')} {
		font-size: 68px;
		padding-bottom: 40px;
		margin-bottom: 18px;
	}
	@media ${breakpoint('desktop')} {
		font-size: 90px;
		padding-bottom: 60px;
		margin-bottom: 25px;
	}
`

const Description = styled.p`
	font-weight: 800;
	text-transform: uppercase;
	font-size: 12px;

	@media ${breakpoint('tablet')} {
		line-height: 2;
	}
`

const TickerModule = ({ data, isActive }) => {
	const easingFn = (t, b, c, d) => {
		if ((t /= d / 2) < 1) return (c / 2) * t * t + b
		return (-c / 2) * (--t * (t - 2) - 1) + b
	}

	const tickerElement = useRef(null)

	useEffect(() => {
		if (isActive) {
			tickerElement.current.start()
		}
	}, [isActive])

	const { numberStat, copy } = data

	const shadowText = numberStat ? `${numberStat.toLocaleString()}+` : 0

	return (
		<TickerWrapper>
			<TickerInner>
				<CountUp
					start={null}
					ref={tickerElement}
					end={numberStat}
					duration={2.75}
					startOnMount={false}
					separator=","
					suffix="+"
					useEasing
				>
					{({ countUpRef }) => (
						<StatisticNumber
							ref={countUpRef}
							title={shadowText}
							easingFn={easingFn}
						></StatisticNumber>
					)}
				</CountUp>
				<Description dangerouslySetInnerHTML={{ __html: copy }}></Description>
			</TickerInner>
		</TickerWrapper>
	)
}

export default TickerModule
