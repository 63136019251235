import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import TickerModule from '../../components/tickerModule'
import Grid from '../../elements/grid'
import SectionTransition from '../../elements/activeSectionDefault'
import { breakpoint } from '../../shared/theme'

const StatsWrapper = styled(SectionTransition)`
	margin-top: 16px;
	margin-bottom: 16px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 156px;
		margin-top: 0;
	}
`

const StatsTicker = ({ isActive, statistics }) => {
	if (!statistics && statistics.length <= 0) {
		return null
	}

	return (
		<StatsWrapper isActive={isActive}>
			<InnerWrapper>
				<Grid desktopColumns={12}>
					{statistics.map((item, index) => (
						<TickerModule data={item} key={index} isActive={isActive} />
					))}
				</Grid>
			</InnerWrapper>
		</StatsWrapper>
	)
}

StatsTicker.propTypes = {
	isActive: PropTypes.bool,
	statistics: PropTypes.array,
}

StatsTicker.defaultProps = {
	isActive: true,
	statistics: [],
}

export default StatsTicker
