import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SectionTransition from '../../elements/activeSectionDefault'
import { breakpoint, getColor } from '../../shared/theme'

const CategoryTilesWrapper = styled(SectionTransition)`
	padding-top: 12px;

	@media ${breakpoint('tablet')} {
		padding: 30px 0 200px;
	}
`

const Tiles = styled.div`
	display: grid;
	grid-template-columns: repeat(6, minmax(0, 1fr));
	column-gap: 12px;
	row-gap: 12px;

	@media ${breakpoint('desktop')} {
		column-gap: 24px;
	}
`

const TileWrapper = styled.div`
	grid-column: span 3;
	overflow: hidden;
	position: relative;
	display: block;
	width: 100%;
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		position: relative;
		padding-top: 100%;
	}

	@media ${breakpoint('tablet')} {
		grid-column: span 2;
	}

	@media ${breakpoint('laptop')} {
		grid-column: span 1;
	}
`

const Image = styled.img``

const Title = styled.p`
	position: absolute;
	bottom: 20px;
	left: 20px;
	color: ${getColor('beige')};
	font-weight: 800;
	text-transform: uppercase;

	@media ${breakpoint('tablet')} {
		font-size: 12px;
	}
`

const CategoryTiles = ({ isActive, categories }) => {
	if (!categories || categories.length <= 0) {
		return
	}

	return (
		<CategoryTilesWrapper isActive={isActive}>
			<Tiles>
				{categories.map((item, index) => (
					<TileWrapper key={index}>
						<Image
							src={item.image.mediaItemUrl}
							className="bg-cover"
							alt={item.copy}
						/>
						<Title className="category-title">{item.copy}</Title>
					</TileWrapper>
				))}
			</Tiles>
		</CategoryTilesWrapper>
	)
}

CategoryTiles.propTypes = {
	isActive: PropTypes.bool,
	categories: PropTypes.array,
}

CategoryTiles.defaultProps = {
	isActive: true,
	categories: [],
}

export default CategoryTiles
