import React from 'react'
import styled, { css } from 'styled-components'
import { getColor, breakpoint } from '../../shared/theme'

const Link = styled.a`
	background-color: ${(props) =>
		props.colortheme == 'white' ? getColor('white') : getColor('black')};
	border: 1px solid
		${(props) =>
			props.colortheme == 'white' ? getColor('black') : getColor('white')};
	color: ${(props) =>
		props.colortheme == 'white' ? getColor('black') : getColor('white')};
	font-weight: 800;
	text-transform: uppercase;
	text-align: left;
	position: relative;
	grid-column: span 14;
	font-size: 8px;
	padding: 2.5em 6.25em 2.5em 2em;
	display: inline-block;
	min-width: 210px;

	${(props) =>
		props.colortheme == 'switch' &&
		css`
			background-color: ${getColor('white')};
			color: ${getColor('black')};
			border: 1px solid ${getColor('black')};
		`}

	@media ${breakpoint('tablet')} {
		padding: 2.5em 8.3em 2.5em 2.5em;
		font-size: 12px;
		min-width: 275px;

		${(props) =>
			props.colortheme == 'switch' &&
			css`
				background-color: ${getColor('black')};
				color: ${getColor('white')};
				border: 1px solid ${getColor('white')};
			`}
	}

	@media ${breakpoint('desktop')} {
		min-width: 365px;
	}

	svg {
		position: absolute;
		right: 22px;
		top: 22px;
		width: 11px;
		height: 11px;

		polyline,
		line {
			stroke: ${(props) =>
				props.colortheme == 'white' ? getColor('black') : getColor('white')};
			transition: stroke ${(props) => props.theme.transitionSpeed.default};
			stroke-width: 2;
			stroke-miterlimit: 10;
			stroke-linecap: 'square';

			${(props) =>
				props.colortheme == 'switch' &&
				css`
					stroke: ${getColor('black')};
				`}
		}

		@media ${breakpoint('tablet')} {
			top: 28px;
			width: 16px;
			height: 16px;
			right: 30px;

			polyline,
			line {
				${(props) =>
					props.colortheme == 'switch' &&
					css`
						stroke: ${getColor('white')};
					`}
			}
		}
	}

	&:hover {
		background-color: ${(props) =>
			props.colortheme == 'white' ? getColor('black') : getColor('white')};
		color: ${(props) =>
			props.colortheme == 'white' ? getColor('white') : getColor('black')};

		${(props) =>
			props.colortheme == 'switch' &&
			css`
				background-color: ${getColor('black')};
				color: ${getColor('white')};
				border: 1px solid ${getColor('white')};
			`}

		svg {
			polyline,
			line {
				stroke: ${(props) =>
					props.colortheme == 'white' ? getColor('white') : getColor('black')};
				${(props) =>
					props.colortheme == 'switch' &&
					css`
						stroke: ${getColor('white')};
					`}
			}
		}

		transition: all ${(props) => props.theme.transitionSpeed.default};

		@media ${breakpoint('tablet')} {
			${(props) =>
				props.colortheme == 'switch' &&
				css`
					background-color: ${getColor('white')};
					color: ${getColor('black')};
					border: 1px solid ${getColor('white')};
				`}

			svg {
				polyline,
				line {
					${(props) =>
						props.colortheme == 'switch' &&
						css`
							stroke: ${getColor('black')};
						`}
				}
			}
		}
	}
`

const ArrowButton = ({ title, link, colortheme }) => {
	return (
		<Link href={link} colortheme={colortheme}>
			{title}
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.4 18.5">
				<polyline
					fill="none"
					points="7.6,17.1 15,9.3 
					7.6,1.4 "
				/>
				<line fill="none" x1="15" y1="9.3" x2="0" y2="9.3" />
			</svg>
		</Link>
	)
}

export default ArrowButton
