import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import H3 from '../../elements/typography/h3'
import SectionTransition from '../../elements/activeSectionDefault'
import WpLink from '../../elements/wpLink'
import { breakpoint, getColor } from '../../shared/theme'

const ProcessWrapper = styled(SectionTransition)`
	padding: 98px 0 108px;
	display: none;

	@media ${breakpoint('tablet')} {
		padding: 85px 0 150px;
		display: block;
	}
`

const ProcessTopSection = styled.div`
	display: flex;
	position: relative;
`

const SubTitle = styled.h6`
	grid-column: span 12;
	color: ${getColor('black')};
	text-transform: uppercase;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: 22px;
	position: absolute;
	left: 0;
	display: none;
	line-height: 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 2;
		margin-bottom: 0;
		display: block;
	}
`

const Title = styled.h4`
	font-size: 19px;
	font-weight: 800;
	text-align: center;
	align-self: center;
	position: relative;
	padding-bottom: 48px;
	margin-bottom: 48px;
	flex: 1;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		height: 3px;
		width: 45px;
		background-color: ${getColor('black')};
		transform: translateX(-50%);
	}

	@media ${breakpoint('tablet')} {
		font-size: 18px;
		text-transform: uppercase;
		margin-bottom: 80px;
		padding-bottom: 22px;
	}
`

const ProcessBottomSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const ContentInner = styled(H3)`
	margin-bottom: 20px;
	text-align: center;
	font-size: 19px;

	p {
		font: inherit;
		margin-bottom: 1.42em;

		@media ${breakpoint('tablet')} {
			margin-bottom: 1em;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media ${breakpoint('tablet')} {
		font-size: 28px;
		letter-spacing: -1px;
		line-height: 1.36;
		max-width: 775px;
		margin-bottom: 100px;
	}
`

const LinkWrap = styled(H3)`
	transition: color ${(props) => props.theme.transitionSpeed.default};
	letter-spacing: -1px;
	text-align: center;
	text-decoration: underline;

	&:hover {
		color: ${getColor('orange')};
	}
`

const ProcessSection = ({ isActive, subheading, heading, copy, link }) => {
	return (
		<ProcessWrapper isActive={isActive}>
			<InnerWrapper>
				<ProcessTopSection>
					<SubTitle>{subheading}</SubTitle>
					{heading && <Title>{heading}</Title>}
				</ProcessTopSection>
				<ProcessBottomSection>
					{copy && (
						<ContentInner>
							<span dangerouslySetInnerHTML={{ __html: copy }} />
						</ContentInner>
					)}
					{link && (
						<WpLink uri={link.url} title={link.title}>
							<LinkWrap>{link.title}</LinkWrap>
						</WpLink>
					)}
				</ProcessBottomSection>
			</InnerWrapper>
		</ProcessWrapper>
	)
}

ProcessSection.propTypes = {
	isActive: PropTypes.bool,
	subheading: PropTypes.string,
	heading: PropTypes.string,
	copy: PropTypes.string,
	link: PropTypes.object,
}

ProcessSection.defaultProps = {
	isActive: true,
	subheading: '',
	heading: '',
	copy: '',
	link: {},
}

export default ProcessSection
